const LinkedInart = 
`
888      d8b          888                    888 8888888          
888      Y8P          888                    888   888            
888                   888                    888   888            
888      888 88888b.  888  888  .d88b.   .d88888   888   88888b.  
888      888 888 "88b 888 .88P d8P  Y8b d88" 888   888   888 "88b 
888      888 888  888 888888K  88888888 888  888   888   888  888 
888      888 888  888 888 "88b Y8b.     Y88b 888   888   888  888 
88888888 888 888  888 888  888  "Y8888   "Y88888 8888888 888  888 
`;

export default LinkedInart;
