const pc =
`                ooooooooooooooooooooooooooooooooooooo
                8                                .d88
                8  oooooooooooooooooooooooooooood8888
                    8  8888888888888888888888888P"   8888    
                    8  8888888888888888888888P"      8888    
                    8  8888888888888888888P"         8888    
                    8  8888888888888888P"            8888    
                    8  8888888888888P"               8888    
                    8  8888888888P"                  8888    
                    8  8888888P"                     8888    
                    8  8888P"                        8888    
                    8  8888oooooooooooooooooooooocgmm8888    
                    8 .od88888888888888888888888888888888    
                    8888888888888888888888888888888888888    
                                                             
                        ooooooooooooooooooooooooooooooo      
                    d                       ...oood8b        
                    d              ...oood888888888888b      
                    d     ...oood88888888888888888888888b    `


export default pc;