

const tabs = {
    home: "/home/",
    expertise: "/expertise/",
    experience: "/experience/",
    projects: "/projects/",
    contact: "/contact/"
  };
  
  export default tabs;
  