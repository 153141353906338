const eagle =
`⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⣀⠀⣀⣀⣀⡀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
⠀⠀⠀⠀⠀⠀⠀⠀⡰⠒⠈⠀⠀⠀⠀⠀⠀⠀⠑⢄⡀⠀⠀⠀⠀⠀⠀⠀
⠀⠀⠀⠀⠀⢀⠰⠃⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢁⡌⠄⢀⠀⠀⠀⠀⠀
⠀⠀⠀⠀⠀⠋⠀⠀⠀⠀⢀⣀⣀⣤⣤⡤⣤⣶⢷⡿⢃⡤⠆⠊⠀⠢⡀⠀
⠀⠀⠀⠀⠃⠀⠀⠀⠀⠀⠀⠀⠺⠿⣤⣅⣿⣿⠟⠁⠀⠀⠀⠀⠀⠀⠈⡄
⠀⠀⠀⠇⠀⠠⠀⢀⡔⠀⠀⢀⠀⠈⠟⣛⣻⣣⣤⣬⠭⠀⠤⠤⠤⢆⠀⠀
⠀⠀⡜⡠⢂⢀⡴⠋⠀⠠⠊⠉⢀⣀⣤⣾⣿⣿⡟⠋⠀⠀⠀⠀⠀⢠⠔⠁
⠀⢠⣿⣧⣿⠟⢀⠄⡀⠀⠀⢀⣾⣿⣿⣿⣿⢛⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
⠀⣽⣿⣿⣿⡶⣥⠞⡰⣀⣰⣿⣿⣿⣿⣿⣿⠀⢖⠀⠀⠀⠀⠀⠀⠀⠀⠀
⢀⣿⣿⣿⣯⣼⣿⣿⣿⣿⣿⠟⣿⣿⣿⣿⡏⠀⣨⣧⠀⠀⠀⠀⠀⠀⠀⠀
⠀⡿⣿⣿⣿⣿⣿⣿⣿⣵⣣⣼⣿⣿⣿⣿⣿⣦⣿⠃⠀⠀⠀⠀⠀⠀⠀⠀
⠀⠀⠉⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⡿⡄⠀⠀⠀⠀⠀⠀⠀⠀
⠀⠀⠀⢹⣿⣿⣿⡿⢿⣿⣿⣿⡿⢻⠟⢿⣿⠛⠓⠀⠀⠀⠀⠀⠀⠀⠀⠀
⠀⠀⠀⠈⠙⠋⠋⠀⢿⣿⡟⠁⠀⠈⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
⠀⠀⠀⠀⠀⠀⠀⠀⠈⠁⠁⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀`
export default eagle;