const diploma =
`     .odbo.
           .od88888888bo.       
      .od8888888888888888bo.
      .od88888888888888888888888888bo.
      od88888888888888888888888888888888bo
  ~888888888888888888888888888888~
    ~888888888888888888888888~ |
       ~888888888888888888~    |
         |~888888888888~|      |
         \   888888~    /      A
           -_   ~~   _-        H
  --____--`

export default diploma;